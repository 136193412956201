import "./index.css"

import { graphql } from "gatsby"
import React from "react"
import { Trans } from "react-i18next"
import { useTranslation } from "react-i18next"

import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import PatternLateral from "../../components/patternLateral/PatternLateral"

export default function IndexPage() {
  const { t } = useTranslation()

  return (
    <Layout>
      <Seo title={t("Política de privacidad")} />

      <PatternLateral />
      <h2 className="sectionTitle">
        <Trans>Política de privacidad</Trans>
      </h2>

      <div className="mainText legalBody" id="formTop">
        La Política de Privacidad forma parte del Aviso Legal que rige la Página
        Web: <b>www.latribu.es</b> junto con la Normativa de Uso de Cookies.
        <br />
        <br />
        La página Web www.latribu.es es titularidad de La Tribu Animation, S.L.
        y cumple con los requisitos derivados de la Ley 34/2002, de 11 de julio,
        de Servicios de la Sociedad de la Información y de Comercio Electrónico,
        y normativa vigente relativa a la protección de datos de carácter
        personal y, en particular, el Reglamento (UE) 2016/679 del Parlamento
        Europeo y del Consejo, de 27 de abril de 2016, relativo a la protección
        de las personas físicas en lo que respecta al tratamiento de datos
        personales y a la libre circulación de estos dato.
        <br />
        <br />
        La Tribu Animation, S.L. se reserva el derecho a modificar o adaptar la
        presente Política de Privacidad en todo momento. Por lo tanto, le
        recomendamos que revise la misma cada vez que acceda a la Página Web. En
        el supuesto de que usuario se haya registrado en la página web y acceda
        a su cuenta o perfil, al acceder a la misma, se le informará en el
        supuesto de que haya habido modificaciones sustanciales en relación con
        el tratamiento de sus datos personales.
        <br />
        <br />
        <h3 className="basic">
          <b>Quién es el RESPONSABLE DEL TRATAMIENTO?</b>
        </h3>
        Los datos que se recojan o nos facilite voluntariamente por medio de la
        Página Web, ya sea por la navegación en la misma, así como todos
        aquellos que nos pueda facilitar en los formularios de contacto, vía
        email o por teléfono, serán recabados y tratados por el Responsable del
        Fichero, cuyos datos se indican a continuación:
        <br />
        <br />
        Identidad: <b> La Tribu Animation, S.L.</b>
        <br />
        CIF: <b>B98763113</b>
        <br />
        Dirección postal:{" "}
        <b>
          Avenida Doctor Peset Aleixandre 58 1B
          <br />
          46025 – Valencia (Valencia)
        </b>
        <br />
        Teléfono: <b>960883879</b>
        <br />
        Correo-e: <b>contact@latribu.es</b>
        <br />
        Contacto DPD.: (en su caso)
        <br />
        URL:<b> www.latribu.es</b>
        <br />
        Registro Mercantil:{" "}
        <b>Valencia, Tomo …, Libro …, Folio …, Sección …, Hoja V-….</b>
        <br />
        <br />
        Si, por cualquier motivo, quiere ponerse en contacto con nosotros en
        cualquier cuestión relacionada con el tratamiento de sus datos
        personales o privacidad (con nuestro Delegado de Protección de Datos),
        puede hacerlo a través de cualquiera de los medios indicados
        anteriormente.
        <br />
        <br />
        <h3 className="basic">
          Cuándo, por qué, quién, cómo, para qué y durante cuánto tiempo
          tratamos sus datos personales?
        </h3>
        <h5>Cuándo y por qué?</h5>
        Puede navegar por la mayoría de nuestras páginas web sin facilitar
        ninguna información personal, pero en algunos casos esta información es
        necesaria para proporcionarle los servicios electrónicos que nos
        solicita.
        <br />
        <br />
        Si necesitamos recabar datos personales para prestarle el servicio,
        trataremos la información según la política expuesta en este documento y
        en las condiciones específicas del concreto Servicio de que se trate (si
        las hubiere), que contienen declaraciones específicas de privacidad
        sobre el uso de los datos y le informan de por qué, para qué, cómo,
        durante cuánto tiempo tratamos sus datos personales y qué medidas de
        seguridad implementamos.
        <br />
        <br />
        <h5>Quién recaba sus datos?</h5>
        La recogida y tratamiento de los datos de carácter personal que nos
        pueda facilitar, se llevan a cabo por nuestra entidad o, en su caso, sus
        encargados del tratamiento. En relación a este último caso, estos
        encargados son terceros a los que se exige por contrato que su actividad
        respete la legalidad e implementen las medidas de seguridad adecuadas
        para proteger dichos datos.
        <br />
        <br />
        <h5>Para qué?</h5>
        Los datos personales que le solicitamos, o que nos facilites en razón de
        su navegación, nos sirven para gestionar, prestar y mejorar los
        servicios que nos ha solicitado.
        <br />
        <br />
        Por ejemplo, trataremos datos personales suyos para poder gestionar las
        consultas que nos envíe, para poder gestionar su participación en
        procesos de selección de personal, para enviarle comunicaciones
        electrónicas en el caso de que así nos lo solicitase, y/o para la
        confección de estadísticas.
        <br />
        <br />
        En este sentido, le solicitamos un correo electrónico cuando usa
        nuestros formularios de contacto en la web. Solo recogemos los datos
        personales del remitente necesarios para responderle.
        <br />
        <br />
        Cuando se suscribes a nuestros boletines también le solicitamos un
        correo electrónico para poder prestarle el servicio, en cualquier caso
        puede gestionar su baja del servicio cuando lo desee y ponemos medios a
        su disposición para hacerlo.
        <br />
        <br />
        <h5>Cómo tratamos sus datos?</h5>
        Recogemos exclusivamente la información personal en la medida necesaria
        para alcanzar un propósito específico. La información no se utilizará
        para una finalidad incompatible con la descrita.
        <br />
        <br />
        Solamente revelamos la información a terceros si es necesaria para el
        cumplimiento de la finalidad del servicio y únicamente a las personas
        que deben conocerlos. Todo ello al objeto de que se pueda prestar el
        servicio tratando sus datos personales con confidencialidad y reserva,
        conforme a la legislación vigente.
        <br />
        <br />
        En todo caso, nuestra entidad adopta medidas de seguridad para proteger
        los datos contra un posible abuso o acceso no autorizado, alteración o
        pérdida.
        <br />
        <br />
        <h5>Cuánto tiempo guardamos sus datos?</h5>
        Guardamos los datos solamente por el tiempo necesario para cumplir la
        finalidad de su recogida o de su procesamiento posterior. El periodo de
        conservación de los datos dependerá del servicio y en cada servicio se
        indicará la duración del tratamiento de datos personales.
        <br />
        <br />
        Al final de este documento le facilitamos una tabla con los periodos
        específicos de conservación.
        <br />
        <br />
        <h3 className="basic">
          Con qué finalidades vamos a tratar sus datos personales?
        </h3>
        <b>Clientes:&nbsp;</b>Tratamos sus datos personales con la finalidad de
        (i) gestionar su compra o servicio prestado; (ii) mantener la relación
        contractual y precontractual para la facturación, elaboración de
        presupuestos y hacer seguimiento del mismo así como remitirle
        información por medios electrónicos que versen sobre tu solicitud; (iii)
        envío de comunicaciones sobre información comercial por medios
        electrónicos que puedan resultar de su interés, siempre que exista
        autorización expresa; (iv) podremos elaborar un perfil comercial con
        base en la información que nos facilite con el fin de poder ofrecerle
        productos y servicios de acuerdo con sus intereses. No se tomarán
        decisiones automatizadas en base a dicho perfil.
        <br />
        <br />
        <b>Proveedores: </b>Tratamos sus datos personales con la finalidad de
        (i) facturación y (ii) mantener el contacto comercial, (iii) así como
        remitirle información por medios electrónicos sobre nuestros productos o
        servicios.
        <br />
        <br />
        <b>Contactos de la web o del correo electrónico: </b>
        Tratamos sus datos personales con la finalidad de (i) contestar sus
        consultas y solicitudes; (ii) gestionar el servicio solicitado o
        tramitar su pedido; (iii) remitirle información comercial por medios
        electrónicos que puedan resultar de su interés, siempre que exista
        autorización expresa; (iv) podremos elaborar un perfil comercial con
        base en la información que nos facilite con el fin de poder ofrecerle
        productos y servicios de acuerdo con sus intereses. No se tomarán
        decisiones automatizadas en
        <br />
        base a dicho perfil.
        <br />
        <br />
        <b>Contactos redes sociales: </b>Tratamos sus datos personales con la
        finalidad de (i) contestar sus consultas y solicitudes, (ii) gestionar
        el servicio solicitado, contestar su solicitud o tramitar su pedido e
        (iii) interactuar contigo y crear una comunidad de seguidores.
        <br />
        <br />
        <b>Demandantes de empleo: </b>Tratamos sus datos personales con la
        finalidad de (i) contar contigo en los procesos de selección de
        contratación, (ii) citarle para entrevistas de trabajo y evaluar su
        candidatura, (iii) comunicar su curriculum a empresas del grupo,
        colaboradoras o afines con el único objetivo de hacerle partícipe en sus
        procesos de selección, siempre que nos hayas dado su consentimiento.
        <br />
        <br />
        <b>Participantes en nuestros concursos: </b>Tratamos sus datos
        personales con la finalidad de gestionar su participación en los
        concursos que organicemos así como publicitar los ganadores del concurso
        y el acto de entrega de premios.&nbsp;La participantes que resulten
        premiados podrán ser fotografiados o grabadas en vídeo y difundidas en
        cualquiera de los medios, nuestra web u otros medios de comunicación. En
        consecuencia es posible que la imagen de los participantes sea captada,
        grabada y/o reproducida de modo accesorio a la actividad principal.
        <br />
        <br />
        <b>Usuarios de la web:&nbsp;</b>Por navegar por nuestra web recopilamos
        información sobre su navegador, su dispositivo y los datos del uso que
        realice de nuestra página web así como cualquier información que nos
        proporcione al utilizar nuestra web. De forma anonimizada o agregada,
        podremos registrar la dirección IP (número de identificación del acceso
        a Internet del dispositivo, que permite a los dispositivos, sistemas y
        servidores reconocerse y comunicarse entre sí).
        <br />
        <br />
        La finalidad del tratamiento es (i) obtener un conocimiento práctico
        sobre el modo en que los usuarios utilizan nuestra página web que nos
        permita mejorarla;(ii) realizar análisis estadísticos que nos ayude a
        mejorar nuestra estrategia comercial; (iii) realizar analíticas de
        rendimiento de la web y (iv) por seguridad técnica y diagnóstico de
        sistemas.Los datos que obtenemos no está relacionada con un usuario
        concreto y se almacenará en nuestras bases de datos.
        <br />
        <br />
        Los datos anteriormente mencionados, así como los datos personales que
        en su caso nos facilite, se almacenan mediante cookies que se recogen en
        un formato seudonimizados y se encuentran sujetos a la presentación de
        objeciones al tratamiento de estos datos personales, como se detalla en
        la Política de Cookies.
        <br />
        Puede consultar la Política de Cookies en el apartado correspondiente.
        <br />
        <br />
        La información de su navegación puede almacenarse mediante Google
        Analytics,por lo que nos remitimos a la Política de Privacidad de
        Google, ya que éste recaba y trata tal información.
        Http://www.google.com/intl/en/policies/privacy/
        <br />
        <br />
        Del mismo modo, desde nuestra página web se puede facilitar la utilidad
        de Google Maps, la cual podría tener acceso a su ubicación, en el
        supuesto de que se lo permita, con el fin de facilitarte una mayor
        especificidad sobre la distancia y/o caminos a nuestras sedes. A este
        respecto, nos remitimos a la política de privacidad utilizada por Google
        Maps, con el fin de conocer el uso y tratamiento de tales datos
        http://www.google.com/intl/en/policies/privacy/
        <br />
        <br />
        Con objeto de ofrecer información o servicios de interés en función de
        la localización del Usuario, podremos acceder a datos relativos a la
        geolocalización del dispositivo del Usuario en aquellos casos en que la
        configuración del usuario al efecto así lo permita.
        <br />
        <br />
        El Portal puede ofrecer funcionalidades para compartir contenidos a
        través de aplicaciones de terceros, como Facebook o Twitter. Estas
        aplicaciones pueden recoger y tratar información relacionada con la
        navegación del usuario en los diferentes sitios webs. Cualquier
        información personal que se recabada a través de estas aplicaciones
        puede ser utilizada por terceros usuarios de las mismas. Sus
        interacciones están sometidas a las políticas de privacidad de las
        compañías que facilitan las aplicaciones.
        <br />
        <br />
        El Portal puede alojar blogs, foros, y otras aplicaciones o servicios de
        redes sociales con la finalidad de facilitar el intercambio de
        conocimiento y contenido. Cualquier información personal que se facilite
        por el usuario puede ser compartida con otros usuarios de ese servicio,
        sobre los cuales no tenemos control alguno.
        <br />
        <br />
        <h3 className="basic">
          Cuál es la legitimación para el tratamiento de sus datos?
        </h3>
        <b>Clientes</b>: La base legal para el tratamiento de sus datos es (i)
        la ejecución de un contrato y mantenimiento de la relación contractual y
        (ii) su consentimiento que se le solicita para la remisión de ofertas de
        productos y servicios a través de medios electrónicos, sin que en ningún
        caso la retirada de este consentimiento condicione la ejecución del
        contrato.
        <br />
        <br />
        <b>Proveedores</b>: La base legal para el tratamiento de sus datos es
        (i) la ejecución de un contrato en el que el interesado es parte o para
        la aplicación de medidas precontractuales.
        <br />
        <br />
        <b>Contactos de la web o del correo electrónico: </b>La base legal para
        el tratamiento de sus datos es (i) el consentimiento del interesado.
        <br />
        <br />
        En aquellos casos donde para realizar una solicitud sea necesario
        cumplimentar un formulario y hacer un «click» en el botón de enviar, la
        realización del mismo implicará necesariamente que ha sido informado y
        ha otorgado expresamente su consentimiento al contenido de la cláusula
        anexada a dicho formulario o aceptación de la política de privacidad.
        <br />
        <br />
        Todos nuestros formularios cuentan con una casilla de verificación que
        es necesario marcar para acceder a los servicios que se ofrecen.
        <br />
        <br />
        Las finalidades del tratamiento serán las siguientes:
        <br />
        <br />
        a) Gestionar las consultas o solicitudes de información que nos envíes a
        través de la Página Web, correo electrónico o teléfono.
        <br />
        <br />
        b) Envío de comunicaciones, promociones especiales, noticias o acciones
        que resulten de tu interés o nos solicites incluso por medios
        electrónicos. Al tratarse de una finalidad accesoria a la principal,
        deberás marcar la casilla habilitada a tal efecto.
        <br />
        <br />
        Los datos personales que nos facilites por este medio no serán
        comunicados a terceros, siendo La Tribu Animation, S.L. quien dé,
        directamente respuesta a este tipo de consultas.
        <br />
        <br />
        <b>Contactos redes sociales:&nbsp;</b>La base legal para el tratamiento
        de sus datos es la aceptación de la relación contractual con el
        proveedor de la red social correspondiente manifestada al darse de alta
        en su aplicación y conforme a sus políticas de privacidad, que es
        externa a nosotros.
        <br />
        <br />
        <b>Trabaja con nosotros:&nbsp;</b>En el supuesto de que nos facilite su
        curriculum vitae, ya sea por medio de la Página Web, correo electrónico
        o físicamente en el domicilio o cualquier sede de La Tribu Animation,
        S.L. los incorporará en su base de datos. El curriculum será almacenado
        durante el plazo de 1 año, tras el cual, en el caso de no habernos
        puesto en contacto contigo, será eliminado.
        <br />
        <br />
        La base legal para el tratamiento se basará en el consentimiento expreso
        otorgado por el interesado para el tratamiento de los datos contenidos
        en el curriculum al remitir el mismo y marcar la casilla habilitada a
        tal efecto.
        <br />
        <br />
        La finalidad del tratamiento es incorporarle a presentes y futuros
        procesos de selección de La Tribu Animation, S.L. o de cualquier entidad
        perteneciente al grupo empresarial.
        <br />
        <br />
        En el supuesto de que finalmente, el interesado se incorpore como
        empleado a La Tribu Animation, S.L. o cualquiera de las entidades
        pertenecientes al grupo empresarial, sus datos se
        <br />
        incorporarán a una base de datos propiedad del mismo, con el fin de
        gestionar internamente la relación laboral empleado- empleador.
        <br />
        <br />
        <b>Envío de Newsletter:&nbsp;</b>En la Página Web se permite la opción
        de suscribirse a la Newsletter de La Tribu Animation, S.L. Para ello, es
        necesario que nos facilite una dirección de correo electrónico a la que
        se remitirá la misma Tal información será almacenada en una base de
        datos de La Tribu Animation, S.L. en la cual quedará registrada hasta
        que el interesado solicite la baja de la misma o, en su caso, se cese
        por parte de La Tribu Animation, S.L. del envío de la misma.
        <br />
        <br />
        La base legal para el tratamiento de estos datos personales es el
        consentimiento expreso prestado por todos aquellos interesados que se
        suscriban en este servicio marcando la casilla destinada a tal efecto.
        <br />
        <br />
        Los datos de los correos electrónicos serán únicamente tratados y
        almacenados con la finalidad de gestionar el envío de la Newsletter por
        parte de los usuarios que soliciten la misma.
        <br />
        <br />
        <b>Participantes en nuestros concursos:&nbsp;</b>La base legal para el
        tratamiento de sus datos es su consentimiento al inscribirse al concurso
        y aceptar la política de privacidad y las bases del concurso.
        <br />
        <br />
        Los datos personales que se recaben no se cederán a terceros.
        <br />
        <br />
        <b>Usuarios de la web:&nbsp;</b>La base legal para el tratamiento de los
        datos es nuestro (i) interés legítimo en conocer los modos de navegación
        de nuestros usuarios para adaptarnos a sus intereses y mejorar nuestra
        relación con ellos; así como (ii) su consentimiento al navegar por
        nuestra web y aceptar los términos de uso de las cookies.
        <br />
        <br />
        <h3 className="basic">A qué destinatarios se comunicarán sus datos?</h3>
        Sus datos no se cederán a terceros ajenos al servicio que prestamos,
        salvo obligación legal. En concreto se comunicarán a la Agencia Estatal
        de la Administración Tributaria y a bancos y entidades financieras para
        el cobro del servicio prestado o producto adquirido.
        <br />
        <br />
        También podrán ser comunicados sus datos a nuestros proveedores de
        servicios cuando sea necesario para la ejecución del contrato. En estos
        casos, el encargado de tratamiento se ha comprometido por contrato a
        emplear los datos sólo para el fin que justifica el tratamiento y
        mantener medidas de seguridad adecuadas.
        <br />
        <br />
        <h3 className="basic">Qué medidas de seguridad aplicamos?</h3>
        Puede estar tranquilo, hemos adoptado medidas técnicas y organizativas
        adecuadas para garantizar la confidencialidad, integridad y
        disponibilidad en el tratamiento de sus datos personales que realizamos,
        en concreto aquellas que evitan la pérdida, mal uso, alteración, acceso
        no autorizado y robo de los datos personales.
        <br />
        <br />
        <h3 className="basic">
          Cuáles son sus derechos cuando nos facilita sus datos?
        </h3>
        Puede ejercer sus derechos de acceso, rectificación, supresión,
        portabilidad, limitación u oposición al tratamiento de sus datos,
        incluido el derecho a retirar su consentimiento prestado, conforme se
        detalla a continuación:
        <br />
        <br />
        <ul>
          <li>
            <b>Derecho de acceso:</b> Puedes preguntarnos si estamos tratando
            sus datos y de qué manera.
          </li>
          <li>
            <b>Derecho de rectificación:</b> Puedes pedirnos que actualicemos
            tus datos personales si son incorrectos, y suprimirlos si así lo
            deseas.
          </li>
          <li>
            <b>Derecho de limitación del tratamiento:</b> En este caso
            únicamente serán conservados por nosotros para el ejercicio o la
            defensa de reclamaciones.
          </li>
          <li>
            <b>Derecho de oposición:</b> Tras tu solicitud de oposición al
            tratamiento, dejaremos de tratar los datos en la forma que indiques,
            salvo que por motivos legítimos imperiosos o el ejercicio o la
            defensa de posibles reclamaciones se tengan que seguir tratando.
          </li>
          <li>
            <b>Derecho a la portabilidad de los datos:</b> En caso de que
            quieras que tus datos sean tratados por otra empresa, te
            facilitaremos la portabilidad de tus datos al nuevo responsable.
          </li>
          <li>
            <b>Derecho de supresión:</b> Puedes solicitar que eliminemos tus
            datos cuando ya no sean necesarios para el tratamiento, retires tu
            consentimiento, sea un tratamiento ilícito o haya una obligación
            legal de hacerlo. Analizaremos el supuesto y aplicaremos la ley.
          </li>
        </ul>
        Si necesitas más información sobre qué derechos tienes reconocidos en la
        Ley y cómo ejercerlos, te recomendamos dirigirte a la Agencia Española
        de Protección de Datos, que es la autoridad de control en materia de
        protección de datos.
        <br />
        <br />
        Puede dirigirse al Delegado de Protección de Datos con carácter previo a
        la presentación de una reclamación contra el responsable del tratamiento
        ante la
        <br />
        AEPD.
        <br />
        <br />
        En el caso de que no te hayamos atendido el ejercicio de sus derechos
        puedes presentar una reclamación ante la Agencia Española de Protección
        de Datos.
        <br />
        <br />
        Disponemos de formularios para el ejercicio de derechos que pueden ser
        solicitados al correo electrónico citado más arriba; también puede
        utilizar los elaborados por la Agencia Española de Protección de Datos o
        los de terceros. Estos formularios deberán ir firmados electrónicamente
        o ser acompañados de fotocopia del DNI. Si se actúa por medio de
        representante de la misma manera deberá ir acompañado de copia de su DNI
        o con firma electrónica.
        <br />
        <br />
        Los formularios deberán ser presentados presencialmente o remitidos por
        correo postal o electrónico en las direcciones que aparecen en el
        apartado “Responsable”. El plazo máximo para resolver es el de un mes a
        contar desde la recepción de su solicitud.
        <br />
        <br />
        <h3 className="basic">Por cuánto tiempo conservaremos sus datos?</h3>
        Los datos personales serán mantenidos mientras mantenga la vinculación
        con nosotros.
        <br />
        <br />
        Al finalizar el mismo, los datos personales tratados en cada una de las
        finalidades indicadas se mantendrán durante los plazos legalmente
        previstos. En caso de no existir ese plazo legal hasta que el interesado
        solicite su supresión o revoque el consentimiento otorgado, o durante el
        plazo que un juez o tribunal los pueda requerir atendiendo al plazo de
        prescripción de acciones judiciales.
        <br />
        <br />
        En cada tratamiento o tipología de datos, te facilitamos un periodo
        específico, que puedes consultar en la siguiente tabla:
        <br />
        <br />
        <b>FICHERO</b>
        <ul>
          <li>
            Clientes:
            <ul>
              <li>Facturas: 10 años.</li>
              <li>Contratos: 5 años.</li>
            </ul>
          </li>
          <li>
            Recursos Humanos
            <ul>
              <li>Nóminas, TC1, TC2, etc: 10 años.</li>
              <li>
                Currículums: hasta el fin del proceso de selección, y 1 año más
                con tu consentimiento.
              </li>
              <li>Contratos: 4 años.</li>
              <li>Datos de trabajadores temporales: 4 años.</li>
              <li>Expediente del trabajador: hasta 5 años tras la baja.</li>
            </ul>
          </li>
          <li>
            Márketing
            <ul>
              <li>
                Bases de datos o visitantes de la web: mientras dure el
                tratamiento.
              </li>
            </ul>
          </li>
          <li>
            Proveedores
            <ul>
              <li>Facturas: 10 años.</li>
            </ul>
          </li>
        </ul>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
